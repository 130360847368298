app.service('clientStatementService', [ 'CONSTANTS', '$window', '$ngConfirm', 'portalHelper', 'HttpAuth',
  function (CONSTANTS, $window, $ngConfirm, portalHelper, HttpAuth) {
    var service = this;

    service.REPORT_DOWNLOAD_FORMATS = {
      HTML: 'html',
      PDF: 'pdf',
      XLSX: 'xlsx'
    };

    service.QUERY_STRING_PARAMS = {
      CLIENT_ID: 'ci',
      START_DATE: 'sd',
      END_DATE: 'ed',
      GROUP_BY_PROJECT: 'gp',
      COMBINED_REPORT: 'cr',
      YEAR: 'yr',
      CLIENT: 'cc'
    };

    service.construct_query_args = function (clientIds, format, startDate, endDate, groupByProject, year, client_id,
      combineToSingleReport) {

      var queryArgs = {};

      if (format == service.REPORT_DOWNLOAD_FORMATS.HTML) {
        queryArgs['body_only'] =  '1';
      }

      queryArgs['client_id'] = clientIds;

      if (moment.isDate(startDate)) {
        queryArgs['licence_start_date_from'] = moment(startDate).format(CONSTANTS.dateTimeFormatMoment.API_DATE);
      }

      if (moment.isDate(endDate)) {
        queryArgs['licence_start_date_to'] = moment(endDate).format(CONSTANTS.dateTimeFormatMoment.API_DATE);
      }

      if (groupByProject && groupByProject == true) {
        queryArgs['group_by_project'] = '1';
      }

      if (year) {
        queryArgs['year'] = year;
      }

      if (client_id) {
        queryArgs['client'] = client_id;
      }

      if (combineToSingleReport && combineToSingleReport == true) {
        queryArgs['is_combined_report'] = '1';
      }

      return queryArgs;
    };

    service.fetch_download_url = function (format) {
      return '/reports/client/statement/' + format + '/';
    };

    var showHtmlReportViaPopup = function (clientId, reportOptions) {
      var url = service.generate_html_report_url(
        [clientId],
        reportOptions.start_date,
        reportOptions.end_date,
        reportOptions.group_surveys_by_project,
        true);

      portalHelper.popup_window_open(url);
    };

    var downloadReport = function (format, clientId, reportOptions) {
      if (format === service.REPORT_DOWNLOAD_FORMATS.HTML) {
        portalHelper.showPopupError('An error occurred',
          'HTML reports cannot be downloaded.');

        return;
      }

      var downloadUrl = service.fetch_download_url(format);

      var queryArgs = service.construct_query_args(
        [clientId],
        format,
        reportOptions.start_date,
        reportOptions.end_date,
        reportOptions.group_surveys_by_project,
        true);

      portalHelper.showToast(CONSTANTS.typeOfToasts.SUCCESS,
        'The file will be downloaded momentarily.',
        'Request successful');

      var newTabUrl = portalHelper.prepareDownloadUrl(
        downloadUrl,
        queryArgs
      );
      window.open(newTabUrl, '_blank');
    };

    service.show_client_statement_options_popup = function (clientId, scope) {
      scope.client_statement_report_options = {
        start_date: null,
        end_date: null,
        group_surveys_by_project: true
      };

      scope.date_options = {
        maxDate: new Date(),
        startingDay: 1,
        showWeeks: false,
        popupPlacement: 'bottom'
      };

      $ngConfirm({
        title: 'Client Statement Options',
        contentUrl: portalHelper.getUrlRev('html_templates/dialogs/client_statement_options_popup.ecc30781.html'),
        columnClass: 'large',
        scope: scope,
        closeIcon: true,
        backgroundDismiss: true,
        buttons: {
          'Show Report': {
            btnClass: 'mat ui primary button',
            action: function (scope, button) {
              return showHtmlReportViaPopup(clientId, scope.client_statement_report_options);
            }
          },
          'Download Excel': {
            btnClass: 'mat ui primary button',
            action: function (scope, button) {
              return downloadReport(service.REPORT_DOWNLOAD_FORMATS.XLSX, clientId, scope.client_statement_report_options);
            }
          },
          'Download PDF': {
            btnClass: 'mat ui primary button',
            action: function (scope, button) {
              return downloadReport(service.REPORT_DOWNLOAD_FORMATS.PDF, clientId, scope.client_statement_report_options);
            }
          }
        }
      });
    };

    service.download_report = function (clientIds, format, startDate,
      endDate, groupByProject, combineSingleView , htmlCallback) {
      var downloadUrl = service.fetch_download_url(format);
      var queryArgs = service.construct_query_args(
        clientIds,
        format,
        startDate,
        endDate,
        groupByProject,
        combineSingleView);

      if (format === service.REPORT_DOWNLOAD_FORMATS.HTML) {
        portalHelper.downloadFile(downloadUrl, queryArgs)
          .then(function (result) {
            if (htmlCallback != null && typeof(htmlCallback) === 'function') {
              htmlCallback(result.data);
            }
          })
          .catch(function(error) {
            portalHelper.showErrorCommon(error);
          });
      } else {
        portalHelper.downloadFileWithUrl(downloadUrl, queryArgs);
      }
    };

    service.generate_html_report_url = function (clientIds, startDate, endDate, groupByProject, year, client_id,
      combineSingleView) {

      var params = [];
      params.push(service.QUERY_STRING_PARAMS.CLIENT_ID + '=' + clientIds.join(','));

      if (startDate != null && moment(startDate).isValid()) {
        params.push(service.QUERY_STRING_PARAMS.START_DATE + '=' +
          moment(startDate).format(CONSTANTS.dateTimeFormatMoment.API_DATE));
      }

      if (endDate != null && moment(endDate).isValid()) {
        params.push(service.QUERY_STRING_PARAMS.END_DATE + '=' +
          moment(endDate).format(CONSTANTS.dateTimeFormatMoment.API_DATE));
      }

      if (groupByProject) {
        params.push(service.QUERY_STRING_PARAMS.GROUP_BY_PROJECT + '=1');
      }

      if (year) {
        params.push(service.QUERY_STRING_PARAMS.YEAR + '=' + year);
      }

      if (client_id) {
        params.push(service.QUERY_STRING_PARAMS.CLIENT + '=' + client_id);
      }

      if (combineSingleView) {
        params.push(service.QUERY_STRING_PARAMS.COMBINED_REPORT + '=1');
      }

      var baseUrl = '/admin/#/Admin/Reports/ClientStatementReport?';

      return baseUrl + params.join('&');
    };
  }]);
