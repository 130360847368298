app.service('maintenanceService', ['$rootScope', '$interval',
  'HttpAuth', 'apiHelper', '$location',
  function ($rootScope, $interval,
    HttpAuth, apiHelper, $location) {
    var service = this;
    var now = new Date().getTime();
    service.maintenanceStatusUrl = apiHelper.getApiUrl() +
      '/api/maintenance_window.json?ts=' + now;

    var checkForMaintenance = function () {
      service.fetchMaintenanceStatus().then(function (result) {
        if (service.is_maintenance_running) {
          window.location.href = '/maintenance.c80f4347.html?end_datetime=' +
          new Date(service.maintenance_end_datetime).getTime();
        }
      });
    };

    service.pollMaintenanceStatus = function () {
      $interval(function () {
        checkForMaintenance();
      }, 30000);
    };

    service.fetchMaintenanceStatus = function () {
      return HttpAuth.get(service.maintenanceStatusUrl).then(
        function (result) {
          if (result.data.length === 1) {
            service.is_maintenance_running = result.data[0].maintenance_started;
            service.maintenance_start_datetime = new Date(result.data[0].start_datetime);
            service.maintenance_end_datetime = new Date(result.data[0].end_datetime);
            service.banner_display_duration_hours = result.data[0].banner_display_duration_hours;
          }
        }
      );
    };

    checkForMaintenance();

  }]);