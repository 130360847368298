
app.service('payerSurveyHelper',
  ['CONSTANTS', 'portalHelper', 'dataConvert', '$filter', '$rootScope',
    'stringHelper', function (CONSTANTS, portalHelper, dataConvert, $filter, $rootScope, stringHelper) {
      var payerSurveyHelper = this;

      payerSurveyHelper.submit_survey_confirm = function (is_test_survey, fn_submit_survey, cb_submit_done) {
        var popup_title = 'Submit Survey';
        var popup_text = 'Your response will now be submitted. Do you wish to continue to submit the survey responses?';

        var cur_scope = $rootScope.$new(true);

        if (is_test_survey) {
          popup_title = 'Pilot Testing';
          popup_text = 'Do you wish to complete your pilot survey?';
        }

        cur_scope.confirmed = false;

        portalHelper.showPopupYesNo(
          popup_title,
          popup_text,
          function (scope, button) {
            button.setDisabled(true);
            cur_scope.confirmed = true;
          },
          function () {
            if (cb_submit_done) {
              cb_submit_done();
            }
          },
          null,
          null,
          function () {
            if (cur_scope.confirmed) {
              fn_submit_survey(cb_submit_done, cb_submit_done);
            }
          }
        );
      };

      function highlightRationaleInputBoxes(question) {
        switch (question.type) {
          case CONSTANTS.questionTypeIds.CHECK_BOXES:
            $('textarea#MainContent_rpt_QuestionsGroups_rpt_Questions_rationaleBox_' + question.id)
              .each(
                function (index, item) {
                  if (!item.value && item.willValidate) {
                    item.classList.add('input-error');
                  } else {
                    item.classList.remove('input-error');
                  }
                }
              );
            break;

          case CONSTANTS.questionTypeIds.RATING:
          case CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE:
          case CONSTANTS.questionTypeIds.POINTS_ALLOCATION:
          case CONSTANTS.questionTypeIds.RADIO_RANKING:
            $('#question-input-' + question.id + ' .rationale textarea').each(
              function (index, item) {
                if (!item.value && item.willValidate) {
                  item.classList.add('input-error');
                } else {
                  item.classList.remove('input-error');
                }
              }
            );
            break;
        }
      }

      payerSurveyHelper.validateSingleResponse = function (q, r) {
        var cur_success = true;
        var cur_error_text = '';
        var detailed_message = '';
        var rationale_description = (q.rationale_description) ? q.rationale_description : 'More Information';

        if (q.type == CONSTANTS.questionTypeIds.FREE_TEXT && !r.response_value) {
          cur_success = false;
          detailed_message = 'Please enter a response. It cannot be empty.';
        } else if (q.type == CONSTANTS.questionTypeIds.DROPDOWN_LIST && !r.response_value) {
          cur_success = false;
          detailed_message = 'Please select a response from the dropdown menu.';
        } else if ((q.type == CONSTANTS.questionTypeIds.RATING || q.type == CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE) && q.allow_payer_option !== CONSTANTS.otherOptions.MULTIPLE_OTHER) {
          if (q.type == CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE && r.allow_payer_option && r.payer_option && stringHelper.isNullOrEmpty(r.payer_value)) {
            if (stringHelper.isNullOrEmpty(r.non_discrete_payer_value)) {
              cur_success = false;
              cur_error_text = 'Please enter \'Other\' option value.';
            }
          }

          if (r.allow_payer_option && !r.payer_option && r.payer_value) {
            cur_success = false;
            cur_error_text = 'Please enter text in \'Other\' option.';
          }

          if (!r || !r.rating_responses) {
            cur_success = false;
            cur_error_text = 'Please provide an appropriate response.';
            return { 'cur_success': cur_success, 'detailed_message': cur_error_text };
          }

          var required_answers = q.options.length;
          var cur_answers = Object.keys(r.rating_responses).length;

          if (r.allow_payer_option && r.payer_option) {
            required_answers += 1;
          }

          if (r.allow_payer_option && (!stringHelper.isNullOrEmpty(r.payer_value) || !stringHelper.isNullOrEmpty(r.non_discrete_payer_value))) {
            cur_answers += 1;
          }

          if (!r.rating_responses || required_answers != cur_answers) {
            cur_success = false;
            cur_error_text = 'Please enter text in \'Other\' option.';
          }

          if (!cur_success && !detailed_message) {
            detailed_message = 'Please rate each item in the list.';
          }
        } else if (q.type == CONSTANTS.questionTypeIds.RATING && q.allow_payer_option == CONSTANTS.otherOptions.MULTIPLE_OTHER) {
          if (r.other_options.length == 0) {
            detailed_message = 'Please add at least one option and rate the option.';
            cur_success = false;
          } else {
            cur_answers = 0;
            required_answers = 0;
            for (var k = 0; k < r.other_options.length; k++) {
              if (r.other_options[k].option_name) {
                required_answers += 1;
              }
            }

            if (!required_answers) {
              cur_success = false;
            } else {
              for (var option_name in r.rating_responses) {
                if (r.rating_responses[option_name].value) {
                  cur_answers += 1;
                }
              }

              if (required_answers != cur_answers) {
                cur_success = false;
              }
            }

            if (!cur_success && !detailed_message) {
              detailed_message = 'Please rate each item in the list.';
            }
          }
        } else if (q.type == CONSTANTS.questionTypeIds.DRAG_DROP_RANKING) {
          var requiredToRank = q.scale.items_to_rank == null ? q.scale.options.length : q.scale.items_to_rank;

          if (!r.ranking_responses || (requiredToRank !== Object.keys(r.ranking_responses).length)) {
            cur_success = false;
            detailed_message = 'Please drag and rank ' + requiredToRank + ' items.';
          }
        } else if (q.type == CONSTANTS.questionTypeIds.RADIO_RANKING) {
          if (r.allow_payer_option && r.payer_option && stringHelper.isNullOrEmpty(r.payer_value)) {
            cur_success = false;
            detailed_message = 'Please enter \'Other\' option value.';
          }
          if (r.allow_payer_option && !r.payer_option && r.payer_value) {
            cur_success = false;
            detailed_message = 'Please enter text in \'Other\' option.';
          }

          var scale_values_count = q.scale.values.length;
          var answers_count = r.ranking_responses && Object.keys(r.ranking_responses).length;
          if (q.allow_payer_option && r.payer_value) {
            answers_count += 1;
          }
          if (!r.ranking_responses || (answers_count < scale_values_count)) {
            cur_success = false;
            detailed_message = 'Please assign all the ranks available.';
          }
        } else if (q.type == CONSTANTS.questionTypeIds.RADIO_BUTTONS) {
          if (!r.response_value && !q.allow_payer_option) {
            cur_success = false;
          } else if (!r.response_value && q.allow_payer_option && !r.payer_option) {
            cur_success = false;
          }

          if (!cur_success) {
            detailed_message = 'Please select a response from the list.';
          }

          if (r.payer_option_selected == true && stringHelper.isNullOrEmpty(r.payer_option)) {
            cur_success = false;
            detailed_message = 'Please enter a valid input in the other option textbox.';
          }
        } else if (q.type == CONSTANTS.questionTypeIds.CHECK_BOXES) {
          var count_checked = 0;

          for (var check_name in r.checkbox_responses) {
            if (r.checkbox_responses[check_name]) {
              count_checked += 1;
            }
          }

          if (r.allow_payer_option && r.payer_option) {
            count_checked += 1;
          }

          if (portalHelper.isMultiRationale(q.rationale_type) && r.payer_rationale && !r.payer_option) {
            cur_success = false;
            cur_error_text = q.text + ', please enter other option or remove more info';
          }

          if (!count_checked) {
            cur_success = false;
            detailed_message = 'Please select at least one response from the list.';
          }

          if (r.payer_option_selected == true && stringHelper.isNullOrEmpty(r.payer_option)) {
            cur_success = false;
            detailed_message = 'Please enter a valid input in the other option textbox.';
          }
        } else if (q.type == CONSTANTS.questionTypeIds.POINTS_ALLOCATION) {
          var total_points = 0;
          var correct_point_count = 0;

          for (var option_id in r.points_allocation_responses) {
            if (r.points_allocation_responses[option_id].points || r.points_allocation_responses[option_id].points == 0) {
              total_points += parseInt(r.points_allocation_responses[option_id].points);
              correct_point_count += 1;
            }
          }

          if (correct_point_count != q.options.length) {
            cur_success = false;
            cur_error_text = q.text + ', all options must be set';
            detailed_message = 'Please allocate points to every row in the list. If you wish to, you can enter a value of 0 (zero).';
          }

          if (r.allow_payer_option && r.payer_option && stringHelper.isNullOrEmpty(r.payer_points)) {
            cur_success = false;
            cur_error_text = 'Please enter \'Other\' option value';
          }
          if (r.allow_payer_option && !r.payer_option && r.payer_points) {
            cur_success = false;
            cur_error_text = 'Please enter text in \'Other\' option';
          }

          if (portalHelper.isMultiRationale(q.rationale_type)
        && r.payer_rationale
        && !r.payer_option
          ) {
            cur_success = false;
            cur_error_text = q.text + ', please enter other option or remove more info';
          }

          if (cur_success) {
            if (r.payer_points) {
              total_points += parseInt(r.payer_points);
            }

            if (total_points > q.total_points) {
              cur_success = false;
              cur_error_text = q.text + ', all points must add up to ' + q.total_points;
              detailed_message = 'Please decrease your allocation by ' +
            (total_points - q.total_points) +
            ((total_points - q.total_points) == 1 ? ' point' : ' points');
            } else if (total_points < q.total_points) {
              cur_success = false;
              cur_error_text = q.text + ', all points must add up to ' + q.total_points;
              detailed_message = 'Please allocate ' +
            (q.total_points - total_points) +
            ' more ' +
            ((q.total_points - total_points) == 1 ? ' point' : ' points');
            }
          }
        } else if (q.type == CONSTANTS.questionTypeIds.VAN_WESTENDORP_PRICING_MODEL) {

          if (!r.van_westendorp_responses ||
            stringHelper.isNullOrEmpty(r.van_westendorp_responses.acceptable) ||
            stringHelper.isNullOrEmpty(r.van_westendorp_responses.expensive) ||
            stringHelper.isNullOrEmpty(r.van_westendorp_responses.too_expensive) ||
            stringHelper.isNullOrEmpty(r.van_westendorp_responses.prohibitive)) {
            cur_success = false;
            cur_error_text = q.text + ', required field';
            detailed_message = 'Response to all the sub-questions are required.';
          } else {
            if (q.rationale_mandatory && q.rationale_type == 2) {
              if (stringHelper.isNullOrEmpty(r.van_westendorp_responses.rationale_acceptable) ||
                stringHelper.isNullOrEmpty(r.van_westendorp_responses.rationale_expensive) ||
                stringHelper.isNullOrEmpty(r.van_westendorp_responses.rationale_too_expensive) ||
                stringHelper.isNullOrEmpty(r.van_westendorp_responses.rationale_prohibitive)) {
                cur_success = false;
                $('#question-input-' + q.id + ' .rationale textarea').each(
                  function (index, item) {
                    if (!item.value && item.willValidate) {
                      item.classList.add('input-error');
                    } else {
                      item.classList.remove('input-error');
                    }
                  }
                );
                detailed_message = 'Please complete the highlighted boxes';
              }
            }

            if (q.rationale_mandatory && q.rationale_type == 1) {
              if (stringHelper.isNullOrEmpty(r.rationale)) {
                cur_success = false;
                cur_error_text = q.text + ', required field';
                detailed_message = 'Please complete the \'More Information\' box';
              }
            }

            if (parseFloat(r.van_westendorp_responses.acceptable) >= parseFloat(r.van_westendorp_responses.expensive)) {
              cur_success = false;
              cur_error_text = 'correct 1st or 2nd field';
              detailed_message = 'Please ensure the value of 2nd price point is higher than 1st price point to continue.';

            }

            if (parseFloat(r.van_westendorp_responses.expensive) >= parseFloat(r.van_westendorp_responses.too_expensive)) {
              cur_success = false;
              cur_error_text = 'correct 2nd or 3rd field';
              detailed_message = 'Please ensure the value of 3rd price point is higher than 2nd price point to continue.';
            }

            if (parseFloat(r.van_westendorp_responses.too_expensive) >= parseFloat(r.van_westendorp_responses.prohibitive)) {
              cur_success = false;
              cur_error_text = 'correct 2nd or 3rd field';
              detailed_message = 'Please ensure the value of 4th price point is higher than 3rd price point to continue.';
            }

            if (parseFloat(r.van_westendorp_responses.acceptable) > 99999999.99 ||
              parseFloat(r.van_westendorp_responses.expensive) > 99999999.99 ||
              parseFloat(r.van_westendorp_responses.too_expensive) > 99999999.99 ||
              parseFloat(r.van_westendorp_responses.prohibitive) > 99999999.99) {
              cur_success = false;
              cur_error_text = q.text + ', value should be less than 100000000';
              detailed_message = 'Value of all the sub questions should be less than 100000000';
            }

            if (parseFloat(r.van_westendorp_responses.acceptable) <= 0 ||
              parseFloat(r.van_westendorp_responses.expensive) <= 0 ||
              parseFloat(r.van_westendorp_responses.too_expensive) <= 0 ||
              parseFloat(r.van_westendorp_responses.prohibitive) <= 0) {
              cur_success = false;
              cur_error_text = q.text + ', value should be greater than 0';
              detailed_message = 'Value of all the sub questions should be greater than 0';
            }
          }
        } else if (q.type == CONSTANTS.questionTypeIds.GABOR_GRANGER_PRICING_MODEL) {
          if (q.subquestion == undefined || q.subquestion.is_last !== true) {
            cur_success = false;
            cur_error_text = q.text + ', please complete this question before proceeding';
            detailed_message = 'Please answer this question to proceed';
          }
        }

        if (cur_success && CONSTANTS.multiRationaleQuestions.indexOf(q.type) > -1 &&
          portalHelper.isMultiRationale(q.rationale_type) && q.rationale_mandatory) {

          var response_options = {};
          if (q.type == CONSTANTS.questionTypeIds.CHECK_BOXES) {
            response_options = r.checkbox_responses;
          } else if (q.type == CONSTANTS.questionTypeIds.RATING) {
            response_options = r.rating_responses;
          } else if (q.type == CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE) {
            response_options = r.rating_responses;
          } else if (q.type == CONSTANTS.questionTypeIds.POINTS_ALLOCATION) {
            response_options = r.points_allocation_responses;
          } else if (q.type == CONSTANTS.questionTypeIds.RADIO_RANKING) {
            response_options = r.ranking_responses;
          }


          for (check_name in response_options) {
            if (!response_options[check_name].rationale) {
              cur_success = false;
              detailed_message = 'Please complete the highlighted boxes.';
            }
            highlightRationaleInputBoxes(q);
          }

          if (q.allow_payer_option == CONSTANTS.otherOptions.SINGLE_OTHER && r.payer_option && !r.payer_rationale) {
            cur_success = false;
            if (q.payer_option_rationale_description) {
              detailed_message = 'Please complete the \'' + q.payer_option_rationale_description + '\' box';
            } else {
              detailed_message = 'Please complete the More information box for the \'Other\' option';
            }
          }
        }

        if (cur_success && CONSTANTS.singleRationaleQuestions.indexOf(q.type) > -1 && portalHelper.isSingleRationale(
          q.rationale_type) && q.rationale_mandatory) {
          if (!r.rationale) {
            cur_success = false;
            detailed_message = 'Please complete the \'' + rationale_description + '\' box';
            $('#MainContent_rpt_QuestionsGroups_rpt_Questions_rationaleBox_single_' + q.id).addClass(
              'input-error');
          }
        }

        if (!cur_success && !cur_error_text) {
          cur_error_text = q.text;
        }

        var res = {};
        res.cur_success = cur_success;
        res.cur_error_text = cur_error_text;
        res.detailed_message = detailed_message;

        return res;
      };

      payerSurveyHelper.validate_questions = function (scope, layoutElement) {
        scope.error_list = [];

        var success = true;
        var data = [];
        var total_questions = 0;
        var detail_error_message = '';

        var layoutElements = (layoutElement) ? [layoutElement] : scope.layout;

        for (var i = 0; i < layoutElements.length; i++) {
          if (layoutElements[i].question) {
            total_questions += 1;
            var question_number = total_questions; 
            var error_prefix = question_number + '. ';

            var cur_success = true;
            var cur_error_text = '';
            var q = layoutElements[i].element;

            if (q == null) {
              q = _.findWhere(scope.questions, { id: layoutElements[i].question });
            }

            var r = null;

            if (Object.prototype.hasOwnProperty.call(scope.responses, q.id)) {
              r = scope.responses[q.id];
            } else if (Object.prototype.hasOwnProperty.call(scope.responses_new, q.id)) {
              r = scope.responses_new[q.id];
            }

            if (!r) {
              cur_success = false;
            } else {
              var res = payerSurveyHelper.validateSingleResponse(q, r);
              cur_success = res.cur_success;
              cur_error_text = error_prefix + res.cur_error_text;
              detail_error_message = res.detailed_message;
            }

            var qNum = layoutElements[i].number_label;
            if (qNum == null || qNum == '') {
              qNum = question_number;
            }

            if (scope.response_validations != null &&
            scope.response_validations[q.id] === false) {
              cur_success = false;

              if (q.type === CONSTANTS.questionTypeIds.TABLE_OF_RESPONSES_2) {
                detail_error_message =
                'Please complete all the highlighted sections before you submit the survey.';
              }
            }

            if (!cur_success) {
              success = false;
              scope.error_list.push({
                errorMessage: 'Question ' + qNum + ':',
                questionId: q.id,
                detailMessage: detail_error_message,
                layoutElement: layoutElements[i]
              });
            }
          }
        }

        return success;
      };

      payerSurveyHelper.updateAllResponsesFromServer = function (scope, server_responses) {
        var response_new = [];

        for (var i = 0; i < scope.questions.length; i++) {
          var question = scope.questions[i];
          var response = $filter('filter')(server_responses, { 'question': question.id });

          if (question.type == CONSTANTS.questionTypeIds.DRAG_DROP_RANKING) {
            response = (response.length) ? response[0] : {
              'ranking_responses': [],
              'question': question.id
            };
          } else {
            response = (response.length) ? response[0] : { 'question': question.id };
          }

          if (!response.id) {
            response_new.push(response);
          }

          if (question.type == CONSTANTS.questionTypeIds.DRAG_DROP_RANKING) {
            dataConvert.initRankingResponsesSaved(response, question);
            dataConvert.initRankingResponsesNotSaved(response, question);
          }
        }

        dataConvert.convertMultipleResponseOptions(scope.questions, server_responses);
        dataConvert.convertMultipleResponseOptions(scope.questions, response_new);

        scope.responses_new = dataConvert.convert_to_dict(response_new, 'question');

        portalHelper.populatePayerOptionSelected(server_responses);

        scope.responses = dataConvert.convert_to_dict(server_responses, 'question');

        scope.data_loaded_time = new Date().getTime();
      };

      payerSurveyHelper.getResponses = function (scope, question_id) {
        if (Object.prototype.hasOwnProperty.call(scope.responses, question_id)) {
          return scope.responses;
        } else {
          return scope.responses_new;
        }
      };

      payerSurveyHelper.construct_response_validation_dictionary = function (questions) {
        var result = {};

        for (var i=0; i<questions.length; i++) {
          result[questions[i].id] = null;
        }

        return result;
      };


      payerSurveyHelper.generate_config_with_sub_session = function (includeTimeout, sub_session_id) {
        var responseConfig = {
          headers: {}
        };
        responseConfig.headers[CONSTANTS.SUB_SESSION_HEADER_KEY] = sub_session_id;

        if (includeTimeout) {
          responseConfig['timeout'] = CONSTANTS.REQUEST_TIMEOUT;
        }

        return responseConfig;
      };

    }]
);
