function toggle_active(elt, tableId, api_base) {
  var table = $('#' + tableId).DataTable();
  var cell = table.cell($(elt).parent());
  var row_index = cell.index()['row'];
  var row_data = cell.row(row_index).data();

  $.ajax({
    'url': api_base + '/' + row_data.id + '.json',
    'type': 'PUT',
    'data': {
      id: row_data.id,
      active: !cell.data()
    },
    'success': function (data, status) {
      cell.data(!cell.data()).draw();
    }
  });
}

function iter(o) {
  Object.keys(o).forEach(function (k) {
    if (o[k] !== null && typeof o[k] === 'object') {
      iter(o[k]);
      return;
    }
    if (typeof o[k] === 'string') {
      o[k] = $.fn.dataTable.render.text().display(o[k]);
    }
  });
}

dataTableSafeData = function (object) {
  iter(object);
  return object;
};

dataTableAjaxSafeDataFilter = function (data, type) {
  return JSON.stringify(dataTableSafeData(JSON.parse(data)));
};

if ($.trumbowyg) {
  $.trumbowyg.svgPath = '../lib/thirdparty/trumbowyg/icons.svg';
}

function initToastrOptions() {
  $(document).ready(function () {
    if (typeof toastr == 'undefined') {
      toastr = {};
    }

    toastr.options = {
      'closeButton': true,
      'debug': false,
      'newestOnTop': false,
      'progressBar': true,
      'positionClass': 'toast-bottom-full-width',
      'preventDuplicates': false,
      'onclick': null,
      'showDuration': '300',
      'hideDuration': '1000',
      'timeOut': '5000',
      'extendedTimeOut': '1000',
      'showEasing': 'swing',
      'hideEasing': 'linear',
      'showMethod': 'fadeIn',
      'hideMethod': 'fadeOut'
    };
  });
}

initToastrOptions();

function initDataTablesDefaults() {
  $(document).ready(function () {
    if ($.fn.dataTable != undefined) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: 'search',
          paginate: {
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            first: '<i class="fa fa-angle-double-left"></i>',
            last: '<i class="fa fa-angle-double-right"></i>'
          }
        }
      });
    }
  });
}

initDataTablesDefaults();

function toggle_wrapper(elt, id, active) {
  var eltScope = angular.element(elt).scope();
  eltScope.toggle(elt, id, active);
}

function datatables_action_wrapper(elt, scope_fn_name, data) {
  var eltScope = angular.element(elt).scope();
  eltScope[scope_fn_name](elt, data);
}

function preventDataTableMenuClipping() {
  var dtWrappers = $('.dataTables_wrapper');
  if (dtWrappers != null) {
    dtWrappers.each(function (index, dTable) {
      if ($($(dTable).find('.mat.ui.dropdown-menu')) != null &&
        $($(dTable).find('.mat.ui.dropdown-menu')[0]).find('li') != null) {
        var len = $($(dTable).find('.mat.ui.dropdown-menu')[0]).find('li').length;
        var padding = ((len * 25) + 50) + 'px';
        $(dTable).css('padding-bottom', padding);
      }
    });
  }
}

$(document).ready(function () {
  $('html').click(function () {
    $('.menu-item.drop-down-menu-item').removeClass('drop-down--active');
  });
});

function showMainHeaderMenu(elem, event) {
  $('.menu-item.drop-down-menu-item').removeClass('drop-down--active');

  event.stopPropagation();

  var parent = $(elem).parent();
  parent.addClass('drop-down--active');

  if ($(parent).find('.drop-down-menu-box .search-box').length > 0) {
    $(parent).find('.drop-down-menu-box .search-box').off('click').on('click', function (event) {
      event.stopPropagation();
    });
  }
}

function stopEventPropagation() {
  event.stopPropagation();
}

function copyToClipboard(str) {
  var el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  var selected = document.getSelection().rangeCount > 0
    ? document.getSelection().getRangeAt(0)
    : false;

  el.select();
  document.execCommand('copy');      
  document.body.removeChild(el);

  if (selected) {                                 
    document.getSelection().removeAllRanges();    
    document.getSelection().addRange(selected);   
  }

  if (typeof toastr != 'undefined') {
    toastr['success']('The data was copied to the clipboard successfully.');
  }
}

app.config(['$httpProvider',  '$locationProvider', '$compileProvider', 'SETTINGS',
  function ($httpProvider,  $locationProvider, $compileProvider, SETTINGS) {

    var getCookie = function(cname) {
      var name = cname + '=';
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    };

    var getCSRFCookieName = function () {
      if (SETTINGS.COOKIE_PREFIX) {
        return SETTINGS.COOKIE_PREFIX + 'csrftoken';
      }
      else {
        return 'csrftoken';
      }
    };

    var csrfInterceptor = function ($q) {
      return {
        request: function (config)
        {
          if (config.method !== 'GET') {
            var csrftoken = getCookie(getCSRFCookieName());
            config.headers['X-CSRFtoken'] = csrftoken;
          }
          return config;
        }
      };
    };


    function removeCurlyBraces(str) {
      let previous;
      const REGEX_TO_REMOVE_MATCHING_CURLY_BRACES = /{{(.*?)}}/g;
      do {
        previous = str;
        str = str.replace(REGEX_TO_REMOVE_MATCHING_CURLY_BRACES, '$1');
      } while (str !== previous);
      return str;
    }

    function sanitizeDataForCSTI(data) {
      if (typeof data === 'object' && data !== null) {
        if (data instanceof FormData) {
          const sanitizedFormData = new FormData();
          for (let [key, value] of data.entries()) {
            if (typeof value === 'string') {
              value = removeCurlyBraces(value);
            }
            else if (value instanceof File) {
              sanitizedFormData.append(key, value);
              continue;
            }
            else if (typeof value === 'object') {
              value = sanitizeDataForCSTI(value);
            }
            sanitizedFormData.append(key, value);
          }
          return sanitizedFormData;
        } else {
          let jsonString = JSON.stringify(data);
          if (!jsonString.includes('{{') || !jsonString.includes('}}')) {
            return data;
          }
          jsonString = removeCurlyBraces(jsonString);
          try {
            return JSON.parse(jsonString);
          }
          catch (e) {
            console.info('>>>>>>>>>>>>>>>>>>>>>>>>>>>>Error parsing JSON data: ', e); 
            return data;
          }
        }
      }
      return data;
    }

    var cstiRequestInterceptor = function ($q) {
      return {
        request: function(config) {
          if (config.data && config.method.toUpperCase() !== 'GET') {
            config.data = sanitizeDataForCSTI(config.data);
          }
          return config;
        },
        requestError: function(rejection) {
          return $q.reject(rejection);
        }
      };

    };

    var cstiResponseInterceptor = function ($q) {
      return {
        response: function(response) {
          if (response.data) {
            response.data = sanitizeDataForCSTI(response.data);
          }
          return response;
        },
        responseError: function(rejection) {
          return $q.reject(rejection);
        }
      };
    };

    $httpProvider.defaults.withCredentials = true;

    if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
    }

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|javascript):/);

    $locationProvider.hashPrefix('');

    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

    $httpProvider.interceptors.push(csrfInterceptor);
    $httpProvider.interceptors.push(cstiRequestInterceptor);
    $httpProvider.interceptors.push(cstiResponseInterceptor);
  }]);

app.factory('HttpAuth',
  ['$http', 'authHelper',
    function ($http, authHelper) {
      var serviceObj = {};
      const CACHE_SUPPORTED = 'caches' in window;

      function getAugmentedConfig(cfg) {
        var config = cfg || {};
        var useOrigLogin = !!(config.skip_impersonation);

        config.headers = config.headers || {};

        return config;
      }

      var getCachedResponse = function (url, config, method) {
        return caches.open('mat-cache').then((cache) => {
          let result;
          return cache.match(url).then( (response) => {
            if (response) {
              result = response.json();
            }
            else {
              result = $http[method](url, config).then(
                function (response) {
                  cache.put(url,
                    new Response(
                      JSON.stringify(response),
                      {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      }
                    )
                  );
                  return response;
                }
              );
            }
            return result;
          });
        });
      };

      function handle403(response) {
        if (403 === response.status && response.data && response.data.detail === 'Authentication credentials were not provided.') {
          let addReturnUrl = true;
          authHelper.doLogoutAll(addReturnUrl);
        } else {
          throw response;
        }
      }

      ['delete', 'get', 'head', 'jsonp'].forEach(function (method) {
        serviceObj[method] = function (url, config, useCacheApis) {
          var isMaintenanceUrl = url.indexOf('maintenance_window.json') != -1;
          if (!isMaintenanceUrl) {
            config = getAugmentedConfig(config);
          }

          if (useCacheApis && CACHE_SUPPORTED) {
            var apiPromise = getCachedResponse(url, config, method);
          }
          else {
            apiPromise = $http[method](url, config);
          }

          return apiPromise.then(null, handle403);
        };
      });

      ['post', 'put', 'patch'].forEach(function (method) {
        serviceObj[method] = function (url, data, config) {
          config = getAugmentedConfig(config);
          return $http[method](url, data, config).then(null, handle403);
        };
      });

      return serviceObj;
    }]);

var ACTIVE = '__A__';
var INACTIVE = '__I__';

function startTut(hint_number) {
  introJs().setOption('overlayOpacity', 0.2);

  if (hint_number == 1) {
    introJs().start();
  } else {
    introJs().goToStepNumber(hint_number).start();
  }
}

function allowOneDecimalPlace(field, event) {
  setTimeout(function () {
    var regex = /\d*\.?\d?/g;
    field.value = regex.exec(field.value);
  }, 100);
}
