app.component('payerExpertiseForm', {
  templateUrl: '/components/payer-expertise/payer_expertise_form.component.1db3f191.html',
  controllerAs: '$ctrl',
  controller:
  ['$timeout', '$http', 'HttpAuth', 'apiHelper', '$routeParams', 'portalHelper',
    'authHelper', '$compile', '$scope', 'CONSTANTS', '$filter',
    function ($timeout, $http, HttpAuth, apiHelper, $routeParams, portalHelper,
      authHelper, $compile, $scope, CONSTANTS, $filter) {
      var component = this;
      component.showNextButton = false;
      component.pageNumber = 1;
      component.CONSTANTS = CONSTANTS;
      component.validation_errors = [];

      component.responsesAcrossPages = [];
      component.questionsAcrossPages = {};
      component.responseObject = {};
      component.existingPayer = $routeParams.existingPayer;
      component.mat_date_popup = {
        dateCalOpen: false
      };
      component.openDatePicker = function () {
        component.mat_date_popup.dateCalOpen = true;
      };
      component.dateOptions = {
        datepickerMode: 'month',
        minMode: 'month',
        maxDate: new Date(),
        showWeeks: false
      };

      component.dependentQuestionBanner = '';
      var dateTypeFields = [
        'date', 'month_year'
      ];

      var isDateTypeField = function (element) {
        var allQuestions = component.questionsAcrossPages[component.pageNumber].fields;
        var question = _.filter(allQuestions,
          function (item) {
            return item.internal_name === element.internal_name &&
            dateTypeFields.indexOf(item.field_type) !== -1;
          }
        );

        return question.length;
      };

      var getQuestionIndexOffset = function (pageNumber) {
        if (pageNumber) {
          var countOfQuestions = 0;
          for (var i = 0; i < pageNumber; i++){
            countOfQuestions += component.questionsAcrossPages[i].fields.length;
          }
          return countOfQuestions;
        }
        return 0;
      };

      var isOptionForPreviousResponsePresent = function(options, value, field_type) {
        var flag = true;
        if (field_type === 'checkbox' || field_type === 'multi_select') {
          value.split(';').forEach((val) => {
            if (_.findIndex(options, {'internal_name': val}) === -1) {
              flag = false;
            }
          });
          return flag;
        }
        if (_.findIndex(options, {'internal_name': value}) === -1) {
          return false;
        }
        return true;
      };

      component.check_max_length = function(field, value) {
        if (field.meta && field.meta.max_length) {
          if (value.length > field.meta.max_length) {
            return true;
          }
          angular.element('#input_' + field.internal_name).removeClass('ng-invalid');
        }

        return false;
      };

      component.getQuestionNumber = function (index) {
        return getQuestionIndexOffset(component.pageNumber) + index + 1;
      };

      component.getYearsRange = function() {
        return _.range(new Date().getFullYear(), new Date().getFullYear() - 100);
      };

      component.getPayerExpertiseForm = function (formType) {
        var url = apiHelper.getApiUrl() + '/api/payer_form/' + $routeParams.formId + '/?token=' + $routeParams.token;

        if ($routeParams.existingPayer === 'true') {
          return HttpAuth.post(url);
        }

        return $http.post(url);
      };
      constructResponseFields = function(data) {
        var response = [];
        data.fields.map(function(field) {
          response.push({
            internal_name: field.internal_name,
            display_name: field.display_name,
            value: (field.meta && field.meta.read_only_value) ? field.meta.read_only_value : '',
            value_display_text: '',
            field_type: field.field_type,
            type: field.type,
            previous_response: field.previous_response
          });
        });
        return response;
      };

      var formIdentifier = $routeParams.token;
      var payerId = $routeParams.payerId || 'potential_payer';
      var LOCAL_STORAGE_KEY_PREFIX = 'payerId:' + payerId + '_formId:' + $routeParams.formId + '_token:' + formIdentifier;
      var get_localstorage_key = function () {
        return LOCAL_STORAGE_KEY_PREFIX;
      };

      var retrieveSingleFieldModel = function(record) {
        var retriveModel = {
          'checkbox' : function() {
            var values = record.previous_response.split(';');
            var question =  _.find(component.questionsAcrossPages[component.pageNumber].fields, {'internal_name': record.internal_name});
            var options = _.sortBy(question.options, 'display_order');
            var model = {};
            for (var i = 0; i < values.length; ++i) {
              var index = _.findIndex(options, {'internal_name': values[i]});
              if (-1 != index) {
                model[index] = true;
              }
            }
            return model;
          },
          'multi_select' : function() {
            var values = record.previous_response.split(';');
            var question =  _.find(component.questionsAcrossPages[component.pageNumber].fields, {'internal_name': record.internal_name});
            var options = _.sortBy(question.options, 'display_order');
            var model = [];
            for (var i = 0; i < values.length; ++i) {
              var index = _.findIndex(options, {'internal_name': values[i]});
              if (-1 != index) {
                model.push({
                  'display_name' : options[index].display_name,
                  'display_order': options[index].display_order,
                  'internal_name': options[index].internal_name
                });
              }
            }
            return model;
          }
        };

        return retriveModel[record.field_type]();
      };
      var retrieveSingleField = function (keyPrefix, record) {
        var data = localStorage.getItem(keyPrefix + '_' + record.internal_name);
        var value;
        var isModelValid = true;
        if (!data) {
          if (record.previous_response) {
            value = record.previous_response;
            if (record.field_type === 'month_year') {
              value = '01 ' + value;
            }
          } else {
            return;
          }
        } else {
          value = JSON.parse(data);
        }

        if (record.internal_name in component.telInputHandles) {
          var inputHandle = component.telInputHandles[record.internal_name];
          inputHandle.set_number(value);
          record.value = value;
          return;
        }

        if (isDateTypeField(record)) {
          record.value = new Date(value);
        }
        else {
          if (record.type === 'enumeration') {
            var options = component.questionsAcrossPages[component.pageNumber].fields
              .filter((field) => field.internal_name === record.internal_name)[0].options;
            record.value = isOptionForPreviousResponsePresent(options, value, record.field_type) ? value : null;
            isModelValid = record.value !== null;
          } else {
            record.value = value;
          }
        }
        if (isModelValid) {
          const model_data = localStorage.getItem(keyPrefix + '_' + record.internal_name + '_model');
          if (model_data) {
            record.model = JSON.parse(model_data);
          } else if (record.previous_response &&
              (record.field_type == 'checkbox' || record.field_type == 'multi_select')) {
            record.model = retrieveSingleFieldModel(record);
          }
        }
      };

      var persistSingleField = function (keyPrefix, record) {

        if (record.internal_name in component.telInputHandles) {
          let inputHandle = component.telInputHandles[record.internal_name];
          if (inputHandle.is_valid_number()) {
            record.value = inputHandle.get_number();
          }
          if (record.value) {
            localStorage.setItem(
              keyPrefix + '_' + record.internal_name,
              JSON.stringify(record.value)
            );
          }
          return;
        }

        if (record.value) {
          localStorage.setItem(
            keyPrefix + '_' + record.internal_name,
            JSON.stringify(record.value)
          );
        }
        if (record.model) {
          localStorage.setItem(
            keyPrefix + '_' + record.internal_name + '_model',
            JSON.stringify(record.model)
          );
        }
      };

      var formatDateToMonthYear = function(dateObj) {
        return $filter('date')(dateObj, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_MONTH_YEAR);
      };

      var prepopulateFields = function (responseFields) {
        if (component.existingPayer === 'true') {
          component.me = authHelper.getMeShared();
          for (var i = 0; i < responseFields.length; i++) {
            if (responseFields[i].internal_name === 'firstname') {
              responseFields[i].value = component.me.first_name;
            }
            if (responseFields[i].internal_name === 'lastname') {
              responseFields[i].value = component.me.last_name;
            }
            if (responseFields[i].internal_name === 'email') {
              responseFields[i].value = component.me.email;
            }
          }
        }
      };

      var attachValueDisplayTextToEachResponse = function (responses, pageNumber) {
        var allQuestions = [];
        for (var i = 0; i < pageNumber; i++) {
          allQuestions = allQuestions.concat(component.questionsAcrossPages[i].fields);
        }
        var copyOfResponses = angular.copy(responses);
        _.map(copyOfResponses, function (element) {
          element.value = element.value || '' ;

          var question = _.find(allQuestions, {'internal_name': element.internal_name});
          if (question.options.length) {
            if (question.field_type === 'checkbox' || question.field_type === 'multi_select') {
              var values = element.value.split(';');
              var selectedOptions = _.filter(question.options, function (option) {
                return values.indexOf(option.internal_name) !== -1;
              });
              var display_text_values = _.pluck(selectedOptions, 'display_name');
              element.value_display_text = JSON.stringify(display_text_values);
            }
            else {
              var selectedOption = _.find(question.options, {'internal_name': element.value});
              element.value_display_text = selectedOption ? selectedOption.display_name : '';
            }
          } else  {
            if (question.field_type === 'month_year') {
              element.value_display_text = element.value ? formatDateToMonthYear(new Date(element.value)) : '';
            } else {
              element.value_display_text = element.value;
            }
          }
          return element;
        });
        return copyOfResponses;
      };

      var fetchAlreadySavedData = function (event) {
        $timeout(
          function () {
            var keyPrefix = get_localstorage_key();
            component.responseFields.map(
              function (record) {
                return retrieveSingleField(keyPrefix, record);
              }
            );
          }
        );
      };

      var saveResponsesToLocalStorage = function(event) {
        var keyPrefix = get_localstorage_key();
        return new Promise(function(resolve) {
          $timeout(
            function () {
              component.responseFields.forEach(function (record) {
                persistSingleField(keyPrefix, record);
              });
              resolve(event);
            }
          );

        });
      };

      var initializeAllTelephoneInputs = function () {
        let telInputs = _.filter(
          component.questionsAcrossPages[component.pageNumber].fields,
          {field_type: 'mobile'}
        );
        component.telInputHandles = [];
        for (let i = 0; i < telInputs.length; i++) {
          let selector = '#input_' + telInputs[i].internal_name;
          let elem = angular.element(selector);
          if (elem) {
            let inputHandle = portalHelper.initializeTelephoneInput(selector);
            component.telInputHandles[telInputs[i].internal_name] = inputHandle;
            elem.on('countrychange change', function (e) {
              if (elem.hasClass('ng-invalid')) {
                if (inputHandle.is_valid_number()) {
                  elem.removeClass('ng-invalid');
                  elem.removeClass('is-invalid');
                  $timeout(() => component.validation_errors[telInputs[i].internal_name] = null);
                }
              }
            });
          }
        }
      };

      var initiateAutoSave = function () {
        angular.element(document).ready(
          function () {
            initializeAllTelephoneInputs();

            fetchAlreadySavedData();
          }
        );

        window.onbeforeunload = component.constructAndPersistResponses;

      };

      component.$onInit = function() {
        component.isLoading = true;
        component.getPayerExpertiseForm({formType: component.formType})
          .then(function(result) {
            $timeout(function() {
              let data = result.data;
              data.fields = _.sortBy(data.fields, 'display_order');
              component.formTitle = data.form_name;
              if (data.form_description) {
                component.formDescription = data.form_description;
              }
              component.pageNumber = 0;
              component.responseFields = constructResponseFields(data);
              component.questionsAcrossPages[component.pageNumber] = data;
              component.showNextButton = component.shouldShowNextButton(data);
              component.responseObject = {'response':component.responseFields };
              prepopulateFields(component.responseFields);
              initiateAutoSave();
            });
          })
          .catch(function(err) {
            if ((err.status === 401 || err.status === 403) && err.data.detail === 'Token has been used.') {
              component.isFormAlreadySubmitted = true;
            } else if (err.status === 403 && err.data.detail === 'Invalid Token.') {
              component.isTokenInvalid = true;
            } else {
              portalHelper.showErrorCommon(err);
              component.hasFormErrored = true;
            }
          })
          .finally(function() {
            component.isLoading = false;
          });
      };
      component.shouldShowNextButton = function(formData) {
        var dependentFields = formData.fields.filter(function(field) {
          return field.has_dependent_fields;
        });
        return dependentFields.length > 0 ? true : false;
      };

      component.getNextQuestionSet = function(responseArray, sectionNumber) {
        var url = apiHelper.getApiUrl() + '/api/payer_form/' + $routeParams.formId + '/?token=' + $routeParams.token + '&section_number=' + sectionNumber;
        var data = {'response': responseArray};

        if ($routeParams.existingPayer === 'true') {
          return HttpAuth.post(url, data);
        }

        return $http.post(url, data);
      };

      component.submitForm = function(responseArray, sectionNumber) {
        var url = apiHelper.getApiUrl() + '/api/payer_form/' + $routeParams.formId + '/submit' + '/?token=' + $routeParams.token + '&section_number=' + sectionNumber;
        var data = {'response': responseArray};

        if ($routeParams.existingPayer === 'true') {
          return HttpAuth.post(url, data);
        }

        return $http.post(url, data);
      };

      component.isFieldInvalid = function(payerForm, field) {

        if (field) {
          if (payerForm[field.internal_name].$error.required && payerForm[field.internal_name].$submitted && payerForm[field.internal_name].$untouched) {
            return true;
          }
          if (payerForm[field.internal_name].$error.required && payerForm[field.internal_name].$touched && payerForm[field.internal_name].$invalid) {
            return true;
          }
        }
        return false;
      };
      component.isNumFieldInvalid = function(payerForm, field, index) {
        if (component.isFieldInvalid(payerForm, field)) {
          return true;
        } else if (field.meta && component.responseFields[index] &&(field.meta.min_value > component.responseFields[index].value || field.meta.max_value < component.responseFields[index].value)) {
          return true;
        }
        return false;
      };

      isModelMarkedTrue = function(model) {
        var filtered = Object.keys(model).filter(function(k){
          return model[k];
        });
        if (filtered.length > 0) {
          return true;
        }
        return false;
      };
      component.isAnyCheckboxClicked = function(model) {
        if (model && isModelMarkedTrue(model)) {
          return true;
        }
        return false;
      };

      component.constructCheckboxResponse = function() {
        var checkboxIndices =
        component.questionsAcrossPages[component.pageNumber].fields.map(function(field, i) {
          return field.field_type==='checkbox' ? i : -1;
        })
          .filter(function(index) {
            return index !==-1;
          });
        checkboxIndices.map(function(i) {
          var checkboxResponseField = component.responseFields[i];
          if (!checkboxResponseField.model) {
            return;
          }
          var markedOptions = Object.keys(checkboxResponseField.model);
          var markedTrueOptions = markedOptions.filter(function(key) {
            return checkboxResponseField.model[key];
          });
          component.responseFields[i].value = '';
          var options = component.questionsAcrossPages[component.pageNumber].fields[i].options;
          options =  _.sortBy(options, 'display_order');

          markedTrueOptions.map(function(optionIndex) {
            component.responseFields[i].value = component.responseFields[i].value + options[optionIndex].internal_name + ';';
          });
          component.responseFields[i].value = component.responseFields[i].value.slice(0, -1);
        });
      };
      component.constructMultiSelectResponse = function() {
        var multiSelectQuestionIndices =
        _.map(component.questionsAcrossPages[component.pageNumber].fields, function(field, i) {
          return field.field_type==='multi_select' ? i : -1;
        })
          .filter(function(index) {
            return index !== -1;
          });

        _.map(multiSelectQuestionIndices, function(questionIndex) {

          component.responseFields[questionIndex].value = '';

          var multiSelectField = component.responseFields[questionIndex];
          _.map(multiSelectField.model, function(tags) {
            component.responseFields[questionIndex].value = component.responseFields[questionIndex].value + tags.internal_name + ';';
          });
          component.responseFields[questionIndex].value = component.responseFields[questionIndex].value.slice(0, -1);
        });
      };

      function adjustMonthYearFields(responsesWithValueDisplayText, pageNumber) {
        var responsesCopy = angular.copy(responsesWithValueDisplayText);
        for (var i = 0; i < pageNumber; i++) {
          var monthYearQuestionIndicesOnPage =
          _.map(component.questionsAcrossPages[i].fields, function(field, index) {
            return field.field_type==='month_year' ? index : -1;
          })
            .filter(function(index) {
              return index !== -1;
            });
          _.map(monthYearQuestionIndicesOnPage, function(questionIndex) {
            var monthYearQuestionIndex = getQuestionIndexOffset(i) + questionIndex;
            var dateObj = new Date(responsesCopy[monthYearQuestionIndex].value);
            responsesCopy[monthYearQuestionIndex].value = isNaN(dateObj) ? '' : formatDateToMonthYear(dateObj);
          });
        }
        return responsesCopy;
      }

      component.onBackClick = function() {
        component.processingBackButton = true;
        component.constructAndPersistResponses().then(
          function () {
            component.pageNumber = component.pageNumber - 1;
            component.responseFields = component.responsesAcrossPages[component.pageNumber];
            component.showNextButton = true;
            component.responseObject = {'response':component.responseFields };
            component.validateUSFormForDependentQuestionBanner();
          }
        ).finally(
          function () {
            $timeout(
              function () {
                component.processingBackButton = false;
                $timeout(
                  function () {
                    initializeAllTelephoneInputs();
                    var keyPrefix = get_localstorage_key();
                    var mobile_records = _.filter(
                      component.questionsAcrossPages[component.pageNumber].fields,
                      {field_type: 'mobile'}
                    );
                    for (var index = 0; index < mobile_records.length; index++) {
                      record = mobile_records[index];
                      retrieveSingleField(keyPrefix, record);
                    }
                  }
                );
              }
            );
          }
        );
      };

      var highlightInvalidElement = function (invalidElement) {
        $(invalidElement).addClass('border-glow-animation');
        invalidElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
      };

      var getFirstInvalidElement = function () {

        var allFields = component.questionsAcrossPages[component.pageNumber].fields;

        _.map(allFields, function (item) {

          if (item.field_type === 'multi_select' && item.is_required) {
            angular.element('#input_' + item.internal_name).removeClass('ng-invalid');

            var response = _.filter(component.responseFields, function (response) {
              if (!response.model) {
                return false;
              }
              return response.internal_name === item.internal_name && response.model.length > 0;
            });
            if (response.length > 0) {
              return;
            }
            var invalidElement = angular.element('#input_' + item.internal_name);
            invalidElement.addClass('ng-invalid');
          } else if ('mobile' === item.field_type) {
            invalidElement = angular.element('#input_' + item.internal_name);
            if (authHelper.isUserAdmin() && !invalidElement.value) {
              return;
            }
            var isValidNumber = component.telInputHandles[item.internal_name].is_valid_number();
            if (isValidNumber) {
              invalidElement.removeClass('ng-invalid');
              component.validation_errors[item.internal_name] = null;
            } else {
              var error = component.telInputHandles[item.internal_name].get_validation_error();
              $timeout(() => component.validation_errors[item.internal_name] = error);
              invalidElement.addClass('ng-invalid');
            }
          } else if (item.meta && item.meta.max_length) {
            let element = angular.element('#input_' + item.internal_name);
            if (element[0].value.length > item.meta.max_length) {
              element.addClass('ng-invalid');
            }
          }
        });

        var elements = angular.element('[name=\'' + component.payerForm.$name + '\']');
        var invalidElement = $(elements.find('.ng-invalid:first'))[0];
        return invalidElement;
      };

      component.onMultiSelectTagAdded = function(field) {
        angular.element('#input_' + field.internal_name).removeClass('ng-invalid');
      };

      component.removeInvalidClass = function(field) {
        angular.element(
          '#input_' + field.internal_name
        ).removeClass('is-invalid');
        angular.element(
          '#container_' + field.internal_name + ' input'
        ).removeClass('is-invalid');
      };

      component.onNextClick = function() {
        component.constructAndPersistResponses().then(
          function () {
            invalidElement = getFirstInvalidElement();
            if (invalidElement) {
              highlightInvalidElement(invalidElement);
              return;
            }


            component.processingNextButton = true;
            component.responsesAcrossPages[component.pageNumber] = component.responseFields;
            component.pageNumber = component.pageNumber + 1;
            var responseArr = component.mergeResponse(
              component.responsesAcrossPages,
              component.pageNumber
            );
            var responsesWithValueDisplayText = attachValueDisplayTextToEachResponse(
              responseArr,
              component.pageNumber
            );
            var adjustedResponses = adjustMonthYearFields(responsesWithValueDisplayText, component.pageNumber - 1);
            component.getNextQuestionSet(adjustedResponses, component.pageNumber + 1)
              .then(function(result) {
                $timeout(function() {
                  let data = result.data;
                  data.fields = _.sortBy(data.fields, 'display_order');
                  component.payerForm.$setPristine();
                  component.payerForm.$setUntouched();
                  component.questionsAcrossPages[component.pageNumber] = data;
                  component.responseFields = constructResponseFields(data);
                  component.showNextButton = component.shouldShowNextButton(data);
                  component.responseObject = {'response': responseArr };
                  fetchAlreadySavedData();
                  component.validateUSFormForDependentQuestionBanner();
                });
              })
              .catch(function(err) {
                component.pageNumber = component.pageNumber - 1;
                portalHelper.showErrorCommon(err);
              })
              .finally(function() {
                component.processingNextButton = false;
              });
          }
        );
      };

      component.showTextTypeField = function (fieldType) {
        return (fieldType==='text' || fieldType==='email');
      };

      component.mergeResponse = function(responsesAcrossPages, numberOfPages) {
        var responseArr = [];
        for (var i = 0; i < numberOfPages; i++) {
          responseArr = responseArr.concat(responsesAcrossPages[i]);
        }
        return responseArr;
      };

      component.onSubmitClick = function($event) {
        component.constructAndPersistResponses();
        invalidElement = getFirstInvalidElement();
        if (invalidElement) {
          highlightInvalidElement(invalidElement);
          return;
        }
        component.isSubmitting = true;
        component.responsesAcrossPages[component.pageNumber] = component.responseFields;
        component.pageNumber = component.pageNumber + 1;
        var responseArr = component.mergeResponse(
          component.responsesAcrossPages,
          component.responsesAcrossPages.length
        );
        var responsesWithValueDisplayText = attachValueDisplayTextToEachResponse(
          responseArr,
          component.pageNumber
        );
        var adjustedResponses = adjustMonthYearFields(responsesWithValueDisplayText, component.pageNumber - 1);
        component.submitForm(adjustedResponses  , component.pageNumber + 1)
          .then(function() {
            component.isFormSubmittedSuccessfully = true;
          })
          .catch(function(err) {
            if (err.status === 403 && err.data.detail === 'Token has been used.')
              component.isFormAlreadySubmitted = true;
          })
          .finally(function() {
            component.isSubmitting = false;
            component.pageNumber = component.pageNumber - 1;
          });
      };

      component.isPrePopulatedField = function (field) {
        if (component.existingPayer === 'false') {
          return false;
        }
        var isPrePopulated = ['firstname', 'lastname', 'email'].indexOf(field.internal_name) != -1;
        return isPrePopulated;
      };

      component.searchFilteredList = function (data, query) {
        var options = _.sortBy(data, 'display_order');
        return !query
          ? options
          : _.filter(options, function (elem) {
            return elem.display_name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
          });
      };

      component.constructAndPersistResponses = function () {
        component.constructCheckboxResponse();
        component.constructMultiSelectResponse();
        return saveResponsesToLocalStorage();
      };

      component.validateUSFormForDependentQuestionBanner = function () {
        let bannerText = '';
        if (!(authHelper.isUserAdmin() && 2 == $routeParams.formId)) {
          component.dependentQuestionBanner = bannerText;
          return;
        }

        let index;
        if (1 === component.pageNumber && 0 === component.questionsAcrossPages[1].fields.length) {
          index = component.questionsAcrossPages[0].fields.findIndex(field => field.internal_name === 'payer_type');
          if (index != -1) {
            bannerText = 'Questions on this page will be displayed only if Question '
            + (index + 1) + ' on the previous page has a response.';
          }
        }

        if (2 === component.pageNumber) {
          index = component.questionsAcrossPages[1].fields.findIndex(field => field.internal_name === 'us_organization_type');
          let firstPageQuestionCount = component.questionsAcrossPages[0].fields.length;
          if (index != -1 && !component.responsesAcrossPages[1][index].previous_response
             && !component.responsesAcrossPages[1][index].value) {
            bannerText = 'Questions on this page will be displayed only if Question '
            + (firstPageQuestionCount + index + 1) + ' on the previous page has a response.';
          }
        }
        component.dependentQuestionBanner = bannerText;
      };
    }]});

